<template>
  <div class="card">
    <div class="tab-content">
      <div class="tab-pane fade in active show" id="factures">
        <div class="container">
          <div class="row">
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link active"
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home"
                  type="button"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                >
                  Importation des zones vulnérables
                </a>
              </li>
              <!--<li class="nav-item" role="presentation">
                <a
                  class="nav-link active"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile"
                  type="button"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                >
                  Liste des Zones vulnérables
                </a>
              </li> -->
              <!-- <li class="nav-item" role="presentation">
                              <button
                                class="nav-link"
                                id="contact-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#contact"
                                type="button"
                                role="tab"
                                aria-controls="contact"
                                aria-selected="false"
                              >
                                Contact
                              </button>
                            </li> -->
            </ul>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <!-- <importer-z-v /> -->
              </div>
              <!-- <div
                class="tab-pane fade"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <referentiel-list />
              </div> -->
            </div>
          </div>
          <hr />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import ReferentielList from "./ZoneVulnerableList.vue";
// import ImporterZV from "../../../components/uploader/ImportZoneVulnerable.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    // ReferentielList,
    // ImporterZV,
  },

  computed: {
    ...mapGetters({
      categories: "categorie/categories",
    }),
    referentielName() {
      return "famill";
    },
    emptyReferentielItem() {
      return {
        libelle: null,
        score: null,
        categorie: null,
      };
    },
    tableFields() {
      return [
        "#",
        "index",
        { key: "libelle", label: "Libellé" },
        { key: "code", label: "Code" },
        "actions",
      ];
    },
  },
  created() {
    this.fetchCategories();
  },
  methods: {
    ...mapActions({
      fetchCategories: "categorie/fetchCategories",
    }),
  },
};
</script>

<style></style>
